@charset "UTF-8";
/*------------------------------------------------------------------
BOOTSTRAP REBOOT & GRID
------------------------------------------------------------------*/
/*
*	Custom variables
*/
/*
*	Colors
*/
/*
*	Grid adjustment
*/
/*
*	Breadcrumb
*/
/*
*	Badges (admin only)
*/
/*
*	Burger
*/
.burger {
  appearance: none;
  background: transparent;
  border: 0;
  outline: none; }
  .burger:focus {
    outline: none; }

/*------------------------------------------------------------------
BOOTSTRAP COMPONENTS
------------------------------------------------------------------*/
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #5d8da2 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #416371 !important; }

.text-secondary {
  color: #e58e1a !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #a06312 !important; }

.text-success {
  color: #949b5e !important; }

a.text-success:hover, a.text-success:focus {
  color: #676b41 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f2f2f2 !important; }

a.text-light:hover, a.text-light:focus {
  color: #cccccc !important; }

.text-dark {
  color: #444 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1e1e1e !important; }

.text-darkblue {
  color: #5d8da2 !important; }

a.text-darkblue:hover, a.text-darkblue:focus {
  color: #416371 !important; }

.text-orange {
  color: #e58e1a !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #a06312 !important; }

.text-purple {
  color: #6c5667 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #41343e !important; }

.text-green {
  color: #949b5e !important; }

a.text-green:hover, a.text-green:focus {
  color: #676b41 !important; }

.text-white {
  color: #fff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-black {
  color: #000 !important; }

a.text-black:hover, a.text-black:focus {
  color: black !important; }

.text-gray {
  color: #888888 !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #626262 !important; }

.text-light-gray {
  color: #999999 !important; }

a.text-light-gray:hover, a.text-light-gray:focus {
  color: #737373 !important; }

.text-pale-gray {
  color: #e9ecef !important; }

a.text-pale-gray:hover, a.text-pale-gray:focus {
  color: #bdc6cf !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.badge {
  display: inline-block;
  padding: 5px 5px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #5d8da2; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #4a7182; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 141, 162, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #e58e1a; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #b77215; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(229, 142, 26, 0.5); }

.badge-success {
  color: #fff;
  background-color: #949b5e; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #767b4b; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(148, 155, 94, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f2f2f2; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #d9d9d9; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(242, 242, 242, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #444; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #2b2b2b; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 68, 68, 0.5); }

.badge-darkblue {
  color: #fff;
  background-color: #5d8da2; }
  a.badge-darkblue:hover, a.badge-darkblue:focus {
    color: #fff;
    background-color: #4a7182; }
  a.badge-darkblue:focus, a.badge-darkblue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 141, 162, 0.5); }

.badge-orange {
  color: #212529;
  background-color: #e58e1a; }
  a.badge-orange:hover, a.badge-orange:focus {
    color: #212529;
    background-color: #b77215; }
  a.badge-orange:focus, a.badge-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(229, 142, 26, 0.5); }

.badge-purple {
  color: #fff;
  background-color: #6c5667; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #fff;
    background-color: #503f4c; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 86, 103, 0.5); }

.badge-green {
  color: #fff;
  background-color: #949b5e; }
  a.badge-green:hover, a.badge-green:focus {
    color: #fff;
    background-color: #767b4b; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(148, 155, 94, 0.5); }

.badge-white {
  color: #212529;
  background-color: #fff; }
  a.badge-white:hover, a.badge-white:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-black {
  color: #fff;
  background-color: #000; }
  a.badge-black:hover, a.badge-black:focus {
    color: #fff;
    background-color: black; }
  a.badge-black:focus, a.badge-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.badge-gray {
  color: #fff;
  background-color: #888888; }
  a.badge-gray:hover, a.badge-gray:focus {
    color: #fff;
    background-color: #6f6f6f; }
  a.badge-gray:focus, a.badge-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(136, 136, 136, 0.5); }

.badge-light-gray {
  color: #212529;
  background-color: #999999; }
  a.badge-light-gray:hover, a.badge-light-gray:focus {
    color: #212529;
    background-color: gray; }
  a.badge-light-gray:focus, a.badge-light-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5); }

.badge-pale-gray {
  color: #212529;
  background-color: #e9ecef; }
  a.badge-pale-gray:hover, a.badge-pale-gray:focus {
    color: #212529;
    background-color: #cbd3da; }
  a.badge-pale-gray:focus, a.badge-pale-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 236, 239, 0.5); }

/*------------------------------------------------------------------
PAGES
------------------------------------------------------------------*/
/*
*	ACF repeater border stroke
*/
.acf-repeater.-row > table > tbody > tr > td, .acf-repeater.-block > table > tbody > tr > td {
  border-top-width: 5px;
  border-top-color: #abc; }

.acf-repeater.-row:first table > tbody > tr > td, .acf-repeater.-block:first table > tbody > tr > td {
  border-top-width: 1px; }

.badge-white {
  border: 1px solid #e9ecef; }

input[type=text]:disabled {
  color: black;
  cursor: text; }

.acf-field-58cacac3c8c37 {
  clear: left !important; }

.wp-admin td.page-title {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-font-smoothing: antialiased; }

.wp-admin .badge {
  font-size: 12px;
  font-weight: 400; }

.wp-admin .mce-menu-item >  .mce-text {
  font-size: 14px !important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !important;
  text-transform: none !important;
  font-weight: 400 !important;
  font-style: normal !important; }

.wp-admin .mce-menu-item.mce-menu-item-preview.mce-active > .mce-text {
  color: #fff; }

.wp-admin .disabled {
  pointer-events: none; }
  .wp-admin .disabled > * {
    opacity: .5; }

.wp-admin .acf-field select {
  padding: 2px 8px !important; }

.wp-admin #wpfooter,
.wp-admin #wp-admin-bar-wp-logo,
.wp-admin #wp-admin-bar-new-content,
.wp-admin #wp-admin-bar-my-account .avatar {
  display: none !important; }

.wp-admin #wp-admin-bar-site-name .ab-item {
  padding-left: 8px; }

.wp-admin .hide-if-no-customize {
  display: none !important; }

.wp-admin .widefat th,
.wp-admin .widefat td {
  padding: 10px 8px !important; }
  .wp-admin .widefat th input[type='checkbox'],
  .wp-admin .widefat td input[type='checkbox'] {
    margin-top: 1px; }

.wp-admin th.sortable a,
.wp-admin th.sorted a {
  padding: 6px 0 !important; }

.wp-admin .smaller-editor > .acf-input {
  margin-top: ln(-2); }

.wp-admin .acf-repeater.-table.-empty .acf-table {
  display: none; }

.wp-admin #dashboard-widgets .postbox-container .meta-box-sortables::after {
  display: none !important; }

.wp-admin #local-storage-notice,
.wp-admin #icl_translate_options + br,
.wp-admin #icl_translate_options + br + br,
.wp-admin #icl_translate_options ~ strong + br,
.wp-admin .otgs-ico-help + br,
.wp-admin .otgs-ico-help + br + br,
.wp-admin .toplevel_page_wpseo_dashboard .update-plugins {
  display: none !important; }

.php-error #adminmenuback, .php-error #adminmenuwrap {
  margin-top: 0 !important; }

/*
*	Styles de l'outil d'administration
// scss-lint:disable ImportantRule, QualifyingElement, IdSelector, SelectorFormat
*/
body.login #login .message {
  margin-bottom: 20px; }

body.login #login #loginform  {
  margin-top: 0; }

body.login h1,
body.login #nav,
body.login .wpml-login-ls,
body.login #wpml-login-ls-form {
  display: none; }

body.login #backtoblog {
  margin-bottom: 0;
  text-align: center; }

body.login:not(.interim-login) {
  height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  body.login:not(.interim-login) #login {
    padding-top: 0; }

body.login .language-switcher {
  display: none; }

.wp-admin {
  /* Admin lists */
  /* Badge */
  /* Editor formats menu */
  /* Improve disabled */
  /* Improve page attributes */
  /* Improve post meta box */
  /* Cleanup */
  /* Other improvements and fixes */
  /* WPML */
  /* Media library */
  /* Relevanssi */
  /* Taxonomies */
  /* Filters */
  /* Simplified checklists */
  /* WooCommerce */ }
  @media screen and (min-height: 500px) {
    .wp-admin #wp-auth-check-wrap #wp-auth-check {
      top: 50%;
      transform: translateY(-50%);
      min-height: 400px; } }
  .wp-admin td.page-title {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    -webkit-font-smoothing: antialiased; }
  .wp-admin .badge {
    font-size: 12px;
    font-weight: 400; }
  .wp-admin .mce-menu-item >  .mce-text {
    font-size: 14px !important;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !important;
    text-transform: none !important;
    text-decoration: none !important;
    font-weight: 400 !important;
    font-style: normal !important; }
  .wp-admin .mce-menu-item.mce-menu-item-preview.mce-active > .mce-text {
    color: #fff; }
  .wp-admin .disabled {
    pointer-events: none; }
    .wp-admin .disabled > * {
      opacity: .5; }
  .wp-admin .postbox#pageparentdiv > .inside {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    padding: 1px 12px 7px; }
    .wp-admin .postbox#pageparentdiv > .inside .post-attributes-label-wrapper {
      width: 70px;
      position: relative;
      top: -3px; }
    .wp-admin .postbox#pageparentdiv > .inside select {
      width: calc(100% - 80px);
      max-width: calc(100% - 80px);
      height: 30px; }
    .wp-admin .postbox#pageparentdiv > .inside input {
      height: 30px; }
  .wp-admin .postbox {
    border-radius: 4px; }
    .wp-admin .postbox .post-attributes-help-text,
    .wp-admin .postbox .handle-actions .acf-hndle-cog,
    .wp-admin .postbox .handle-actions .handle-order-higher,
    .wp-admin .postbox .handle-actions .handle-order-lower {
      display: none !important; }
    .wp-admin .postbox .inside.acf-fields.-left .acf-label > label {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      cursor: default !important;
      color: #111;
      position: relative;
      top: 4px;
      font-size: 13px;
      font-weight: 500 !important; }
    .wp-admin .postbox .inside.acf-fields.-left ul.acf-radio-list:focus-within,
    .wp-admin .postbox .inside.acf-fields.-left ul.acf-checkbox-list:focus-within {
      border-color: transparent;
      border-radius: 0; }
  .wp-admin #side-sortables .acf-field.acf-field-true-false.no-label {
    padding: 12px !important; }
  .wp-admin #wpfooter,
  .wp-admin #wp-admin-bar-wp-logo,
  .wp-admin #wp-admin-bar-new-content,
  .wp-admin #wp-admin-bar-archive,
  .wp-admin #wp-admin-bar-wpo_purge_cache,
  .wp-admin #wp-admin-bar-my-account .avatar,
  .wp-admin #wp-admin-bar-ate-status-bar,
  .wp-admin .hide-if-no-customize,
  .wp-admin #local-storage-notice,
  .wp-admin #icl_translate_options ~ *,
  .wp-admin .otgs-installer-notice-wpml.otgs-installer-notice-connection-issues,
  .wp-admin .otgs-ico-help + br,
  .wp-admin .otgs-ico-help + br + br,
  .wp-admin #wp-admin-bar-otgs-wpml-development,
  .wp-admin .toplevel_page_wpseo_dashboard .update-plugins,
  .wp-admin .acf-repeater.-table.-empty .acf-table,
  .wp-admin #dashboard-widgets .postbox-container .meta-box-sortables::after,
  .wp-admin #wp-admin-bar-wpseo-menu,
  .wp-admin #contextual-help-link-wrap,
  .wp-admin .acfml-synchronise-repeatser-checkbox,
  .wp-admin .acfml-synchronise-repeater-checkbox,
  .wp-admin #acfml-field-group-synchronise,
  .wp-admin .acf-admin-notice.acf-escaped-html-notice,
  .wp-admin .mce-widget.mce-notification.mce-notification-info.mce-has-close.mce-in,
  .wp-admin .woocommerce-site-status-badge-live {
    display: none !important; }
  .wp-admin.wp-env-development .yoast-notification.notice.notice-warning,
  .wp-admin.wp-env-development .wpmltm-notice {
    display: none !important; }
  .wp-admin .acf-field select {
    padding: 2px 8px !important; }
  .wp-admin #wp-admin-bar-site-name .ab-item {
    padding-left: 8px; }
  .wp-admin .widefat th,
  .wp-admin .widefat td {
    padding: 10px 8px !important; }
    .wp-admin .widefat th input[type='checkbox'],
    .wp-admin .widefat td input[type='checkbox'] {
      margin-top: 1px; }
  .wp-admin th.sortable a,
  .wp-admin th.sorted a {
    padding: 6px 0 !important; }
  .wp-admin .smaller-editor > .acf-input {
    margin-top: -2rem; }
  .wp-admin .acf-fields.-left > .acf-field {
    padding-top: 12px;
    padding-bottom: 12px; }
  .wp-admin .acf-fields.-left .acf-field.no-group > .acf-label {
    padding-top: 12px;
    padding-bottom: 12px; }
  .wp-admin .acf-fields.-left .acf-field.no-group .acf-input .acf-fields {
    padding: 0;
    border: 0; }
    .wp-admin .acf-fields.-left .acf-field.no-group .acf-input .acf-fields > .acf-field  {
      padding: 12px 0 !important; }
      .wp-admin .acf-fields.-left .acf-field.no-group .acf-input .acf-fields > .acf-field  ~ .acf-field {
        padding-left: 10px !important;
        border-left: 0 !important; }
      .wp-admin .acf-fields.-left .acf-field.no-group .acf-input .acf-fields > .acf-field  ~ .acf-field-google-map {
        padding-left: 0 !important; }
  .wp-admin .acf-field[data-type='tab'] + .smaller-editor > .acf-input {
    margin-top: 0; }
  .wp-admin .acf-radio-list.acf-hl {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .wp-admin .acf-radio-list.acf-hl input[type='radio'] {
      margin-top: -3px; }
  .wp-admin #translation_of_wrap,
  .wp-admin #icl_document_language_dropdown,
  .wp-admin #icl_translation_priority_dropdown,
  .wp-admin .attachment-translation_priority-filter,
  .wp-admin .compat-field-translation_priority,
  .wp-admin .otgs-toggle-group,
  .wp-admin #icl_cfo,
  .wp-admin .otgs-ico-help,
  .wp-admin #icl_minor_change_box,
  .wp-admin #translation-editor-post-settings {
    display: none !important; }
  .wp-admin #icl_set_duplicate {
    display: block !important;
    margin: 10px 0 0 0 !important;
    max-width: 100%; }
  .wp-admin.post-new-php #icl_div {
    display: none !important; }
  .wp-admin #delete-action {
    margin-top: -8px; }
  .wp-admin #icl_translations_table,
  .wp-admin .icl_box_paragraph,
  .wp-admin #icl_translate_options {
    margin-bottom: 0 !important; }
  .wp-admin .icl_box_paragraph > p:first-child {
    margin-bottom: 8px; }
  .wp-admin #translation-editor-post-settings,
  .wp-admin .inside >  br[clear='all'] {
    display: none; }
  .wp-admin .media-sidebar {
    max-width: 50%;
    width: 332px; }
    .wp-admin .media-sidebar .eml-toggle-collapse {
      display: none; }
  .wp-admin .attachments-wrapper {
    right: 365px !important;
    transition: all .25s ease-in-out !important; }
    .wp-admin .attachments-wrapper[style*='right: 0px;'] {
      right: 0 !important; }
  .wp-admin .media-modal.acf-expanded .media-sidebar {
    max-width: 100%; }
  .wp-admin .attachment-details > h2:first-of-type {
    margin-top: 12px !important; }
  .wp-admin .attachment-details #alt-text-description,
  .wp-admin .attachment-details .copy-to-clipboard-container {
    display: none; }
  .wp-admin .attachment-details span.setting {
    margin-top: 0 !important;
    margin-bottom: 4px !important; }
  .wp-admin .attachment-details .attachment-info {
    display: flex !important;
    flex-direction: column; }
  .wp-admin .eml-collapse {
    width: 100% !important;
    height: auto !important;
    display: flex !important;
    flex-direction: column; }
    .wp-admin .eml-collapse span.setting[data-setting='title'] {
      order: -1; }
  .wp-admin .attachments-browser .attachments {
    right: 364px; }
  .wp-admin .attachment-details {
    display: flex !important;
    flex-direction: column; }
    .wp-admin .attachment-details span.setting[data-setting='title'] {
      order: 0; }
    .wp-admin .attachment-details span.setting[data-setting='alt'] {
      order: 1; }
    .wp-admin .attachment-details span.setting[data-setting='alt'] {
      order: 1; }
    .wp-admin .attachment-details span.setting[data-setting='caption'] {
      order: 2; }
    .wp-admin .attachment-details span.setting[data-setting='description'] {
      order: 3; }
  .wp-admin .media-frame-content .attachment-details {
    display: block !important; }
    .wp-admin .media-frame-content .attachment-details .compat-field-media_category .eml-tax-label label {
      width: 127px; }
    .wp-admin .media-frame-content .attachment-details .compat-field-media_category .term-list {
      margin-left: 147px; }
  .wp-admin .acf-gallery-side .term-list {
    margin-left: 0 !important; }
  .wp-admin .compat-attachment-fields .acf-field {
    margin-top: 0 !important;
    margin-bottom: 4px !important; }
  .wp-admin .compat-field-wpo_compress_image {
    padding-top: 32px; }
  .wp-admin .compat-field-media_category .label label {
    display: block;
    text-align: right;
    width: 100px;
    margin-right: 14px; }
    .wp-admin .compat-field-media_category .label label span {
      display: block !important;
      text-align: right !important; }
    .wp-admin .compat-field-media_category .label label br.clear {
      display: none; }
  .wp-admin .compat-field-media_category .term-list {
    background: transparent;
    border: 0;
    padding: 0;
    margin-left: 116px;
    margin-top: 5px; }
    .wp-admin .compat-field-media_category .term-list li, .wp-admin .compat-field-media_category .term-list ul {
      margin-top: 0;
      margin-bottom: 0; }
  .wp-admin .media-types-required-info,
  .wp-admin .acf-expand-details {
    display: none; }
  .wp-admin .acf-gallery-toolbar .acf-hl .acf-fr {
    max-width: calc(100% - 142px); }
  .wp-admin .attachments-browser .compat-item {
    padding-bottom: 1px; }
  .wp-admin .attachments-browser .attachment-display-settings > h2,
  .wp-admin .attachments-browser .attachment-display-settings .setting {
    display: none; }
  .wp-admin .attachments-browser .attachment-display-settings .setting:last-child {
    display: block; }
    .wp-admin .attachments-browser .attachment-display-settings .setting:last-child #attachment-display-settings-size {
      position: relative;
      left: 2px; }
  .wp-admin .attachments-browser .media-toolbar {
    right: 372px; }
    .wp-admin .attachments-browser .media-toolbar .media-toolbar-secondary {
      width: 100%; }
    .wp-admin .attachments-browser .media-toolbar #media-attachment-filters {
      max-width: 30%; }
    .wp-admin .attachments-browser .media-toolbar #media-attachment-media_category-filters {
      max-width: 33.33%; }
    .wp-admin .attachments-browser .media-toolbar #media-search-input {
      width: 100%;
      padding-top: 0;
      padding-bottom: 0; }
    .wp-admin .attachments-browser .media-toolbar #reset-all-filters {
      display: none; }
  .wp-admin.mlmi-auto-size label[for='attachment-display-settings-size'],
  .wp-admin.mlmi-auto-size select#attachment-display-settings-size {
    display: none !important; }
  .wp-admin.mlmi-media-disabled #wp-media-grid > h1 + a,
  .wp-admin.mlmi-media-disabled .attachment-actions,
  .wp-admin.mlmi-media-disabled.post-type-attachment #edit-slug-buttons,
  .wp-admin.mlmi-media-disabled .wp_attachment_image input[id*='imgedit-open-btn-'],
  .wp-admin.mlmi-media-disabled #menu-item-upload,
  .wp-admin.mlmi-media-disabled a.edit-attachment {
    display: none !important; }
  .wp-admin #relevanssi_hidebox p + p {
    margin-top: 0;
    margin-bottom: 4px; }
    .wp-admin #relevanssi_hidebox p + p + p {
      display: none; }
  .wp-admin #relevanssi_hidebox textarea + p {
    margin-top: 2px; }
  .wp-admin #relevanssi_hidebox input[type='checkbox'] {
    margin-right: 2px;
    position: relative;
    top: -1px; }
  .wp-admin form#addtag {
    margin-top: 22px; }
    .wp-admin form#addtag .form-field {
      margin: 6px 0 0 !important; }
      .wp-admin form#addtag .form-field > p {
        display: none; }
    .wp-admin form#addtag .mlmi-builder-section,
    .wp-admin form#addtag #dashboard-widgets {
      display: none; }
  .wp-admin form[name='edittag'] {
    margin-top: 20px; }
    .wp-admin form[name='edittag'] .form-field th,
    .wp-admin form[name='edittag'] .form-field td {
      padding-top: 0; }
      .wp-admin form[name='edittag'] .form-field th p.description,
      .wp-admin form[name='edittag'] .form-field td p.description {
        display: none; }
    .wp-admin form[name='edittag'] .form-field.term-description-wrap .mce-edit-area > iframe {
      height: 180px !important; }
    .wp-admin form[name='edittag'] .form-field .term-permalink {
      font-size: 13px !important;
      padding-left: 1px; }
      .wp-admin form[name='edittag'] .form-field .term-permalink > strong {
        color: #646970 !important;
        padding-right: 2px; }
    .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox-container {
      width: 480px !important; }
    .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] h3.hndle {
      padding: 8px 12px !important;
      font-weight: bold !important;
      margin: 0 !important; }
    .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] .inside  {
      padding-top: 0 !important;
      margin-top: 0 !important; }
    .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options {
      margin-top: 12px;
      margin-bottom: 0; }
      .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options > p {
        margin-bottom: 0 !important; }
        .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options > p > b {
          color: #1d2327; }
      .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options table#icl_translations_table + br {
        display: none; }
      .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options table#icl_translations_table > tbody > tr > td {
        padding-left: 0;
        padding-right: 0;
        width: auto !important; }
      .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options table#icl_translations_table > tbody > tr:first-child > td {
        padding-top: 6px; }
      .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options table#icl_translations_table > tbody > tr:last-child > td {
        padding-bottom: 3px; }
    .wp-admin form[name='edittag'] .form-table {
      width: calc(100vw - 250px);
      max-width: none; }
  .wp-admin #posts-filter #post-query-submit {
    margin-left: 0 !important;
    margin-top: 0 !important; }
  .wp-admin #posts-filter input[name='export_excel'] {
    margin-right: 6px; }
  .wp-admin ul.category-tabs {
    display: none; }
  .wp-admin .tabs-panel {
    max-height: none !important;
    margin: 0 !important;
    padding: 0 0 0 1px !important;
    border: 0 !important; }
    .wp-admin .tabs-panel .categorychecklist {
      margin: 2px 0 0 !important; }
  .wp-admin .woocommerce-help-tip,
  .wp-admin #set-post-thumbnail-desc,
  .wp-admin #quick_edit_notice,
  .wp-admin .wcml-pointer-products_translation {
    display: none !important; }

/*
* Core dashboard
*/
.wp-admin.settings_page_acf-options-core .acf-fields.-left .acf-field.no-group .acf-input .acf-fields > .acf-field {
  padding: 8px 0 !important; }

.wp-admin.settings_page_acf-options-core .acf-field[data-name^='mlmi_core_packages_'] .acf-fields.-border {
  border: 0 !important; }

.wp-admin.settings_page_acf-options-core .type-definition {
  border: 0 !important;
  padding: 0 !important; }

.wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields {
  display: flex;
  flex-flow: row wrap;
  padding: 0 8px 9px 0 !important; }
  .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field {
    flex: 0 0 33.33%; }
    .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field[data-width="50"] {
      flex: 0 0 50%; }
    .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field[data-width="100"] {
      flex: 0 0 100%; }
    .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field.flex-100 {
      flex: 0 0 100%;
      height: 0;
      margin: 0;
      padding: 0 !important; }
      .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field.flex-100 * {
        display: none; }
  .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field {
    padding: 9px 0 0 8px !important;
    border: 0; }
