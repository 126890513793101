/*
*	Custom variables
*/

// Base text family, size and zoom
$font-family-base : 'Roboto', sans-serif;
$font-base-size : 15;
$xl-zoom-breakpoint : 1660px;
$xl-zoom-factor : 1;

// Bootstrap reboot override
$paragraph-margin-bottom : 0;
$headings-margin-bottom : 0;

// Heading sizes
$h1-font-size : (32rem/16);
$h2-font-size : (22rem/16);
$h3-font-size : (20rem/16);
$h4-font-size : (16rem/16);

/*
*	Colors
*/

$enable-gradients: true;
$primary: #5d8da2;
$darkblue: #5d8da2;
$orange: #e58e1a;
$secondary: #e58e1a;
$purple: #6c5667;
$green: #949b5e;
$white: #fff;
$black: #000;
$gray: #888888;
$light-gray: #999999;
$pale-gray: #e9ecef;
$gray-400: #4d4d4d;
$gray-300: #333333;
$gray-100: #f2f2f2;
$off-white: #f5f8f9;
$dark: #444;
$cta-orange: #f68d00;
$cta-orange-hover: #f17500;

$theme-colors: (
	"primary": $primary,
	"darkblue": $darkblue,
	"secondary": $secondary,
	"orange": $orange,
	"purple": $purple,
	"green": $green,
	"white": $white,
	"black": $black,
	"gray": $gray,
	"light-gray": $light-gray,
	"pale-gray": $pale-gray,
	"dark": $dark
);

/*
*	Grid adjustment
*/
$container-max-widths: (
	xl: 1200px
);

/*
*	Breadcrumb
*/

$breadcrumb-bg: $off-white;

/*
*	Badges (admin only)
*/
$badge-padding-x : 5px;
$badge-padding-y : 5px;
$badge-font-size : 12px;
$badge-font-weight : 400;

/*
*	Burger
*/
.burger {
	appearance: none;
	background: transparent;
	border: 0;
	outline: none;
	
	&:focus {
		outline: none;
	}
}

$burger-color : $light-gray;
$burger-stroke-width: 2px;
$burger-padding-x: 12px;
$burger-spacing-y: 5px;
$burger-padding-y: 14px;
$burger-button-height: $burger-padding-y * 2 + $burger-spacing-y * 2 + $burger-stroke-width * 3;
