/*
*	ACF repeater border stroke
*/
.acf-repeater.-row > table > tbody > tr > td, .acf-repeater.-block > table > tbody > tr > td {
	border-top-width:5px;
	border-top-color:#abc;
}

.acf-repeater.-row:first, .acf-repeater.-block:first {
	table > tbody > tr > td {
		border-top-width:1px;
	}
}

.badge-white {
	border:1px solid $pale-gray;
}

input[type=text]:disabled {
	color:black;
	cursor:text;
}

.acf-field-58cacac3c8c37 {
	clear: left !important;
}


.wp-admin {
  td.page-title {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
  }

  .badge {
    font-size: 12px;
    font-weight: 400;
  }

  .mce-menu-item {
    > .mce-text {
      font-size: 14px !important;
      font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !important;
      text-transform: none !important;
      font-weight: 400 !important;
      font-style: normal !important;
    }

    &.mce-menu-item-preview.mce-active {
      > .mce-text {
        color: $white;
      }
    }
  }

  .disabled {
    pointer-events: none;

    > * {
      opacity: .5;
    }
  }

  .acf-field select {
    padding: 2px 8px !important;
  }

  #wpfooter,
  #wp-admin-bar-wp-logo,
  #wp-admin-bar-new-content,
  #wp-admin-bar-my-account .avatar {
    display: none !important;
  }

  #wp-admin-bar-site-name .ab-item {
    padding-left: 8px;
  }

  .hide-if-no-customize {
    display: none !important;
  }

  .widefat th,
  .widefat td {
    padding: 10px 8px !important;

    input[type='checkbox'] {
      margin-top: 1px;
    }
  }

  th.sortable a,
  th.sorted a {
    padding: 6px 0 !important;
  }

  .smaller-editor > .acf-input {
    margin-top: ln(-2);
  }

  .acf-repeater.-table.-empty .acf-table {
    display: none;
  }

  #dashboard-widgets .postbox-container .meta-box-sortables {
    &::after {
      display: none !important;
    }
  }

  #local-storage-notice,
  #icl_translate_options + br,
  #icl_translate_options + br + br,
  #icl_translate_options ~ strong + br,
  .otgs-ico-help + br,
  .otgs-ico-help + br + br,
  .toplevel_page_wpseo_dashboard .update-plugins {
    display: none !important;
  }
}

.php-error #adminmenuback, .php-error #adminmenuwrap {
	margin-top: 0 !important;
}
